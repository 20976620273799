import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const MonthlySales = () => {
  const [options, setOptions] = useState(null);
  const [series, setSeries] = useState(null);

  useEffect(() => {
    const monthlySalesData = [
      { x: new Date("2024-01-01"), y: 100 },
      { x: new Date("2024-02-01"), y: 150 },
      { x: new Date("2024-03-01"), y: 200 },
      { x: new Date("2024-04-01"), y: 180 },
    ];

    setOptions({
      chart: {
        id: "monthly-sales",
        type: "line",
        zoom: {
          enabled: true,
        },
      },
      xaxis: {
        type: "datetime",
        categories: monthlySalesData.map((data) => data.x.getTime()),
      },
      stroke: {
        curve: "smooth",
      },
    });

    setSeries([
      {
        name: "Monthly Sales",
        data: monthlySalesData.map((data) => data.y),
      },
    ]);
  }, []);

  return (
    <>
      <h6>Monthly Sales</h6>
      {options && series && (
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          width={250}
          height={200}
        />
      )}
    </>
  );
};

export default MonthlySales;
