import React from "react";
import DoctorNavbar from "./Components/DoctorDashboard/DoctorNavbar";
import DoctorSideNavbar from "./Components/DoctorDashboard/DoctorSideNavbar";
import { Tab, Tabs, TabList, TabPanels, TabPanel } from "@chakra-ui/react";
import MedicineInventoryDashboard from "../MedicineInventory/MedicineInventoryDashboard";
import ScrollingLists from "../MedicineInventory/Components/Dashboard/ChartComponents/BestAndWorstProducts";
import InventoryDistribution from "../MedicineInventory/Components/Dashboard/ChartComponents/InventoryDistribution";

export default function MedicineInventory() {
  return (
    <>
      <div className="h-100">
        <DoctorNavbar />
        <div className="d-flex">
          <DoctorSideNavbar />
          <div className="d-flex flex-column">
            <Tabs isFitted variant="enclosed">
              <TabList mb="1em">
                <Tab>Dashboard</Tab>
                <Tab>Two</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <MedicineInventoryDashboard />
                  <div className="d-flex">
                    <ScrollingLists />
                    <InventoryDistribution />
                  </div>
                </TabPanel>
                <TabPanel>
                  <p>two!</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
