import React from "react";
import GroomingServiceNavbar from "./Components/Dashboard/GroomingServiceNavbar";
import GroomingServieSideNav from "./Components/Dashboard/GroomingServiceSideNav";
import ApplyLeave from "./Components/ApplyLeave/ApplyLeave";

export default function TakeLeave() {
  return (
    <div className="h-100">
      <GroomingServiceNavbar />
      <div className="d-flex">
        <GroomingServieSideNav />
        <ApplyLeave />
      </div>
    </div>
  );
}
