import { Card, CardBody } from "@chakra-ui/react";
import TopServices from "./ChartComponents/TopServices";
import AppointmentDistribution from "./ChartComponents/AppointmentDistribution";
import MonthlySales from "./ChartComponents/MonthlySales";
import UpcomingLeaves from "./ChartComponents/UpcomingLeaves";
import "./../../styles/GroomingDashboardStyles.css";

export default function GroomingChartsSection() {
  return (
    <>
      <Card width={"80vw"} bg={"#005555"}>
        <CardBody>
          <div className="graphSection">
            <Card className="item">
              <CardBody>
                <TopServices />
              </CardBody>
            </Card>
            <Card className="item">
              <CardBody>
                <AppointmentDistribution />
              </CardBody>
            </Card>
            <Card className="item">
              <CardBody>
                <MonthlySales />
              </CardBody>
            </Card>
            <Card className="item">
              <CardBody>
                <UpcomingLeaves />
              </CardBody>
            </Card>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
