import React from 'react';
import styles from './BookaVet.module.css';
import Header from '../../Components/Header/Header';
import ServiceAboutSection from './ServiceAboutSection';
import Footer from '../../Components/Footer/Footer';

const BookaVet = () => {
  return (
    <>
    <Header/>
    {/* <section className={styles.main}>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8e2dc8306ba5e3dc389b2f5d74ba4e4e00815c75eb6d0498016d15034ec6a31?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20" alt="Hero background" className={styles.heroBackground} />
      <h1 className={styles.BookaVetTitle}>Clinics</h1>
    </section> */}
    <section className={styles.hero}>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8e2dc8306ba5e3dc389b2f5d74ba4e4e00815c75eb6d0498016d15034ec6a31?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20" alt="Hero background" className={styles.heroBackground} />
      <h1 className={styles.heroTitle}>Clinics</h1>
    </section>
    <ServiceAboutSection/>
    <Footer/>
    </>
    
  );
};

export default BookaVet;