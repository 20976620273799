import React, { useState } from "react";
import { Button, border } from "@chakra-ui/react";
import {
  Card,
  CardBody,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Lorem,
} from "@chakra-ui/react";
import * as Unicons from "@iconscout/react-unicons";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import "./../../styles/GroomingInvoicePageStyles.css";
import InvoiceStructureMaking from "../AppointmentsSP/InvoiceStructureMaking";

const initialInvoices = Array.from({ length: 25 }, (_, index) => ({
  fullName: `Customer ${index + 1}`,
  invoiceNumber: `INV-${index + 1}`,
  status: index % 2 === 0 ? "Paid" : "Unpaid",
  date: `2024-04-${index + 1}`,
  amount: `Rs. ${Math.floor(Math.random() * 1000) + 100}.${Math.floor(
    Math.random() * 99
  )}`,
}));

export default function AllInvoicesCard() {
  const [isOpen, setIsOpen] = useState(false);
  function onClose() {
    setIsOpen(false);
  }
  return (
    <>
      <Card width={"80.5vw"} height={"75vh"}>
        <CardBody>
          <div className="d-flex justify-content-between">
            <h3>All Invoices</h3>
            <Button colorScheme="blue" onClick={() => setIsOpen(true)}>
              + Add Invoice
            </Button>
          </div>
          <div className="d-flex " style={{ gap: "1rem", marginTop: ".5rem" }}>
            <InputGroup width={"180vw"}>
              <InputLeftElement pointerEvents="none">
                <Unicons.UilSearch className="d-inline mx-1" />
              </InputLeftElement>
              <Input
                placeholder="Search"
                style={{ border: "2px solid black" }}
              />
            </InputGroup>{" "}
            <Select placeholder="Status" style={{ border: "2px solid black" }}>
              <option value="Paid">Paid</option>{" "}
              <option value="Unpaid">Unpaid</option>
            </Select>
            <Button colorScheme="blue" style={{ width: "25vw" }}>
              Reset
            </Button>
          </div>

          <TableContainer
            style={{
              maxHeight: "55vh",
              overflowY: "scroll",
              fontWeight: "bold",
              marginTop: "1rem",
              borderRadius: "10px",
            }}
            className="table-container"
          >
            <Table variant="striped" colorScheme="#F9F54B" bg={"white"}>
              <Thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: "100",
                }}
              >
                <Tr>
                  <Th>Full Name</Th>
                  <Th>Invoice Number</Th>
                  <Th style={{ textAlign: "center" }}>Status</Th>
                  <Th>Date</Th>
                  <Th>Amount</Th>
                  <Th>Activity</Th>
                </Tr>
              </Thead>
              <Tbody>
                {initialInvoices.map((invoice, index) => (
                  <Tr
                    key={index}
                    bg={index % 2 !== 0 ? "#005555" : "#F9F54B"}
                    color={index % 2 !== 0 ? "#F9F54B" : "#005555"}
                  >
                    <Td>{invoice.fullName}</Td>
                    <Td>{invoice.invoiceNumber}</Td>
                    <Td>
                      <p className={invoice.status}>{invoice.status}</p>
                    </Td>
                    <Td>{invoice.date}</Td>
                    <Td>{invoice.amount}</Td>
                    <Td>
                      <Button
                        variant="ghost"
                        size="sm"
                        mr={2}
                        style={{
                          color: index % 2 === 0 ? "#005555" : "#F9F54B",
                        }}
                      >
                        <Unicons.UilEye className="d-inline mx-1" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        mr={2}
                        style={{
                          color: index % 2 === 0 ? "#005555" : "#F9F54B",
                        }}
                      >
                        <Unicons.UilTrashAlt className="d-inline mx-1" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        style={{
                          color: index % 2 === 0 ? "#005555" : "#F9F54B",
                        }}
                      >
                        <Unicons.UilPrint className="d-inline mx-1" />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Invoice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InvoiceStructureMaking />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
