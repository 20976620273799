import React, { useEffect } from "react";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
} from "@chakra-ui/react";
import * as Unicons from "@iconscout/react-unicons";
import { Link } from "react-router-dom";

const DoctorSideNavbar = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [selectedMenuItem, setSelectedMenuItem] = React.useState("Dashboard");

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  useEffect(() => {
    console.log(selectedMenuItem);
  }, [selectedMenuItem]);

  const renderDropdown = () => {
    return (
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<Unicons.UilApps />}
          w="100%"
          justifyContent="flex-start"
          variant="ghost"
          color="#4793AF"
        >
          {selectedMenuItem}
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => handleMenuItemClick("Dashboard")}
            color="#4793AF"
          >
            Dashboard
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick("Appointments")}
            color="#4793AF"
          >
            Appointments
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick("Invoice Generator")}
            color="#4793AF"
          >
            Invoice Generator
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick("Mark it as a Holiday")}
            color="#4793AF"
          >
            Mark it as a Holiday
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  return (
    <Box
      p={4}
      bg="#D6E4E5"
      w={{ base: "100%", md: "250px" }}
      position={{ base: "fixed", md: "static" }}
      top={{ base: "auto", md: "65px" }}
      left={{ base: "0", md: "auto" }}
      right={{ base: "0", md: "auto" }}
      bottom={{ base: isMobile ? "0" : "auto", md: "auto" }}
      boxShadow="md"
      rounded={{ base: "none", md: "lg" }}
      borderRight={{ base: "none", md: "1px solid #CBD5E0" }}
      borderBottom={{ base: "1px solid #CBD5E0", md: "none" }}
      zIndex="99"
      transform={{ base: "none", md: "translateX(0)" }}
      transition="transform 0.3s ease"
      _hover={!isMobile ? { transform: "translateX(0)" } : {}}
      margin={!isMobile ? "0 1rem" : "0"}
      height={isMobile ? "8vh" : "75vh"}
      width={isMobile ? "100vw" : "250px"}
    >
      {isMobile ? (
        renderDropdown()
      ) : (
        <>
          <Link to="/servicesAgentDashboard">
            <Box mb={4}>
              <Button
                leftIcon={<Unicons.UilDashboard />}
                w="100%"
                justifyContent="flex-start"
                variant="ghost"
                color="#497174"
                onClick={() => handleMenuItemClick("Dashboard")}
                style={{
                  textDecoration:
                    selectedMenuItem === "Dashboard" ? "underline" : "none",
                }}
              >
                Dashboard
              </Button>
            </Box>
          </Link>

          <Link to="/totalAppointmentsSP">
            <Box mb={4}>
              <Button
                leftIcon={<Unicons.UilCalendarAlt />}
                w="100%"
                justifyContent="flex-start"
                variant="ghost"
                color="#497174"
                onClick={() => handleMenuItemClick("Appointments")}
                style={{
                  textDecoration:
                    selectedMenuItem === "Appointments" ? "underline" : "none",
                }}
              >
                Appointments
              </Button>
            </Box>
          </Link>
          <Link to="/medicineInventory">
            <Box mb={4}>
              <Button
                leftIcon={<Unicons.UilMoneyBill />}
                w="100%"
                justifyContent="flex-start"
                variant="ghost"
                color="#497174"
                onClick={() => handleMenuItemClick("Invoice Generator")}
                style={{
                  textDecoration:
                    selectedMenuItem === "Invoice Generator"
                      ? "underline"
                      : "none",
                }}
              >
                Medicines Inventory
              </Button>
            </Box>
          </Link>

          <Link to="/checkLeaves">
            <Box mb={4}>
              <Button
                leftIcon={<Unicons.UilCalender />}
                w="100%"
                justifyContent="flex-start"
                variant="ghost"
                color="#497174"
                onClick={() => handleMenuItemClick("Mark it as a Holiday")}
                style={{
                  textDecoration:
                    selectedMenuItem === "Mark it as a Holiday"
                      ? "underline"
                      : "none",
                }}
              >
                Patients
              </Button>
            </Box>
          </Link>
        </>
      )}
    </Box>
  );
};

export default DoctorSideNavbar;
