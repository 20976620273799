import React from 'react';
import styles from './Services.module.css';
import ServiceButton from './ServiceButton';
import ServiceCard from './ServiceCard';

const Services = () => (
  <section className={styles.services}>
    <h2 className={styles.sectionTitle}>Our Services</h2>
    <div className={styles.servicesGrid}>
      <ServiceCard
        image="https://s3-alpha-sig.figma.com/img/e064/cc8a/7ff494555bf3470584170d907d3d4d51?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iFMs9aKxCJ1BQtHSUiQyIJ1~zgUeUl09qnmKJVOx3LFeFnBSshToQJDe9iz5pPoIMvzca2zDUaFEBYUPkGxC306KjF2gyL79MxB4~cAjC0815aQYSLwc7cT7MGvS5XbKpoLDkagd0oPqHvt~fsaOPFmqL0eEHDrmTdns25PYcaILoVZZ0jPGRzDm4p8jo~vLKruO5hMqiDl8S6CcMRrWttKceooLwMEr4Cc9flfSUfz1Xs~KIErPslnyGda2yvguclt9UHREvIaxZqHCSdxFbbEk9E0pxqEL0Kf4Mh8rPdjOIm7yUlQHsmKs7UzSU2t~tFqwY73flF10qlwbISyloA__"
        title="Day Care"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam enim augue, congue a maximus at, porta non nisi."
        showButton={true}
        height={''}
      />
      <div className={styles.smallServicesContainer}>
        <ServiceCard
          image="https://s3-alpha-sig.figma.com/img/fd55/bc9c/0f146756798cf909d5781c4eb2feedf0?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bbqumojjFnT3wAQ9mLB4a5Jjzg0knUDUR0ti1DSPlDsW9SCeb8~DrTr89r0K2SOi40tHKB1RbVAlt5SBrfjUkyD9rikh6YUMgPLT473rpRZyf9Rp-f2JK05~2-vuHTb57pJ2HymX-36Kfbe3Gzs3FNOXZ272cG8m-wZ4Jxpn8TOSJeOLAkc-NPtIEZeprp5QgILPJ76Z23XY2H7Z8Nb5OtrqP2eq48owLofXTZvMBDLoCEIgnofqR5cWRMwM~q4SjJscwj6EtIYzquuqBncAX9LumOUAbETrznAXseMgY82hASbNeHtKUcsGd3kJcTN7SPyQa5K5IB0-~nUBjKpHRw__"
          title="Grooming"
          description=""
          height={'100%'}
        />
        <ServiceCard
          image="https://cdn.builder.io/api/v1/image/assets/TEMP/b2f59e85262b05503c2c88df387a7bda140090f3eb5bba7dfe7cd90c16696110?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
          title="Training"
          description=""
          height={'100%'}
        />
         </div>
        <div className={styles.smallServicesContainerSecondary}>
        <ServiceCard
          image="https://s3-alpha-sig.figma.com/img/fc1a/c336/a50c1a8af5c409c77ca2a80c35b52204?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=d934qiNYKdOYQPF1mqobR7UF9Q-qzINPIwBAiunCyYx5wbq4PmWoJZH8ySlNA8sVbh0Pd82r9sIcSsiGoFsXlU1phWTF~qGQo4JodrX~tNJ4YN-gX7JBiXUDUkm0sCvTvlsqQBm7muuGw5tp2I3WD3s0cHoArPAAjbefn-K5fXqD7IEPjIAhRgPiIcRLChofOX4c1rV5ATPBO2VXqZR1y5~Vp3SCezOpH-JSHnMV~CdbFUprpIRfsIT5T318JbzcFqzpXzRBptp6NFhhOrhf6-QOuf6UwiP-PfbFY0xRB4j9sf6a9tynrs30dQOiPalBKMwp2W0DkmYrwcaUEOiMUQ__"
          title="Clinics"
          description=""
          height={'100%'}
        />
        <div className={styles.SecondButton}><ServiceButton/></div>
         <ServiceCard
          image="https://s3-alpha-sig.figma.com/img/7290/07a2/993fb597433d21dcdabeb0bdba71e5dd?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Ev0JdMJwkO7X3tDEXrSY3w3Mc5zRNRQeyWdg729grjyalZptIZtarnZ-M4UjQDyr6C~bIBCqlx7OPVGwxSoSYEmgaUyy9E8GPkWHCyd28oqQeNOHT~yw2Y1v9dq5k5IfWDwuDOXsRlsUYR69NNS~p4ndTbpdU7v5Hd5ANHaaAn2gRt6mPwOEjMsP~RNqLcvpjYK7zdc0Tl42Lr0uh-PKoqDwvJU0KyUrts5xfwYoTarV9uIIpQvitH-Q4Wy0~2M8FKyqfrZhbmZot53Nln~R3AquXV9tpmiKEOJuzWrj7OvdLhVP12Hq5q08RaEiwHUb3PvE75JLtcVdZ5oDzEFBhg__"
          title="Mating"
          description=""
          height={'100%'}
        />
        </div>
     
      {/* <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/b43a58c53a28266001d0eb7707b5e6f88f5a6c1a2eb4882fba72617277653059?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="Additional service" className={styles.additionalServiceImage} /> */}
    </div>
  </section>
);

export default Services;