import { Card, CardBody, Image, Text } from "@chakra-ui/react";
import "./styles/CustomerStyles.css";
import CustomerNavbar from "./Components/CustomerNavbar";
import MorningDog from "./../../assets/customer/dashboard/Mrng-Dog.jpg";
import * as Unicons from "@iconscout/react-unicons";

export default function CustomerDashboard() {
  var currentTime = new Date();
  var greetUser =
    currentTime.getHours() > 12
      ? currentTime.getHours() > 18
        ? "Good Evening"
        : "Good Afternoon"
      : "Good Morning";

  return (
    <>
      <div className="CustomerDashboard">
        <div className="CustomerNavbar">
          <Card  width="100%">
            <CardBody>
              <CustomerNavbar />
            </CardBody>
          </Card>
        </div>
        <div className=" CustomerDashboardBody">
          <div className="WelcomeCard">
            <Card backgroundColor="#496989">
              <CardBody className="d-flex">
                <div className="col-lg-10 poppins-medium col-md-12 col-sm-12 col-xs-12">
                  Hi Rama,
                  <br /> {greetUser}
                  <br /> <span className="quoteText">This is a quote</span>
                </div>

                <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                  <Image src={MorningDog} />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="InfoCard">
            <Card backgroundColor="yellow" className="d-flex col-lg-12">
              <CardBody>
                <div className="d-flex">
                  <div className="w-50">
                    <h3 className="px-2">Upcoming Events</h3>
                    <Card backgroundColor="pink" className="d-flex col-lg-12">
                      <CardBody>
                        <div className="d-flex align-items-center upcomingEventCard">
                          <div className="col-lg-9">
                            <h4>General Checkup - Tommy</h4>
                            <div className="d-flex justify-content-between p-1">
                              <p className="col-lg-9 upcomingEventCardClinicName">
                                <Unicons.UilBuilding className="d-inline mx-1" />
                                Clinic Name <br />
                                <Unicons.UilLocationPoint className="d-inline mx-1" />
                                Gachibowli, Hyderabad
                              </p>
                              <p className="col-lg-3">
                                <Unicons.UilCalender className="d-inline mx-1" />
                                06-04-2024 <br />
                                <Unicons.UilClock className="d-inline mx-1" />
                                06:00 PM{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <Image
                              src={MorningDog}
                              className="upcomingEventCardImage"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center upcomingEventCard">
                          <div className="col-lg-9">
                            <h4>General Checkup - Tommy</h4>
                            <div className="d-flex justify-content-between p-1">
                              <p className="col-lg-9 upcomingEventCardClinicName">
                                <Unicons.UilBuilding className="d-inline mx-1" />
                                Clinic Name <br />
                                <Unicons.UilLocationPoint className="d-inline mx-1" />
                                Gachibowli, Hyderabad
                              </p>
                              <p className="col-lg-3">
                                <Unicons.UilCalender className="d-inline mx-1" />
                                06-04-2024 <br />
                                <Unicons.UilClock className="d-inline mx-1" />
                                06:00 PM{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <Image
                              src={MorningDog}
                              className="upcomingEventCardImage"
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="w-50">
                    <div className="d-flex px-2 justify-content-between">
                      <h3>Pet Diary</h3>
                      <p>10-01-2024</p>
                    </div>
                    <Card backgroundColor="orange" className="d-flex col-lg-12">
                      <CardBody>
                        <div className="petDiaryCard">
                          <h3>Gromming for vicky</h3>
                          <p>04:00 PM</p>
                        </div>
                        <div className="petDiaryCard">
                          <h3>Gromming for vicky</h3>
                          <p>04:00 PM</p>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
