import React from "react";
import { Card, CardBody, Image, Button } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import "./../../styles/CustomerStyles.css";
import CustomerNavbar from "../CustomerNavbar";
import * as Unicons from "@iconscout/react-unicons";
import dc from "./../../../../assets/customer/services/dayCare.png";
import wPDF from "./../../../../assets/customer/services/Pw.pdf";

export default function DayCareService() {
  const dayCareServices = [
    "Supervised Playtime",
    "Outdoor Time",
    "Socialization",
    "Resting Areas",
    "Feeding and Medication Administration",
    "Basic Training",
    "Enrichment Activities",
    "Grooming Services",
    "Individual Attention",
    "Regular Updates",
  ];
  const handleDownload = () => {
    const pdfUrl = wPDF;

    const link = document.createElement("a");
    link.href = pdfUrl;

    link.setAttribute("download", "file_name.pdf");

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <>
      <div className="CustomerDashboard">
        <div className="CustomerNavbar">
          <Card  width="100%">
            <CardBody>
              <CustomerNavbar />
            </CardBody>
          </Card>
        </div>
        <div className=" CustomerDashboardBody">
          <div className="WelcomeCard">
            <Card backgroundColor="#496989">
              <CardBody className="d-flex  align-items-center">
                <div className="col-lg-7 poppins-medium col-md-12 col-sm-12 col-xs-12 p-1">
                  <h3>Day Care Service</h3>
                  <p className="serviceDescription">
                    Pet Day Care is a service designed to provide a safe,
                    stimulating, and supervised environment for pets while their
                    owners are away. Our facility offers a range of activities
                    and amenities to keep pets entertained and comfortable
                    throughout the day. With dedicated staff members trained in
                    animal care, pet day care ensures that furry friends receive
                    the attention, exercise, and socialization they need to
                    thrive. Whether it's playing in spacious indoor and outdoor
                    areas, interactive games, or simply lounging in cozy resting
                    areas, pets at our day care enjoy a fun-filled day tailored
                    to their individual preferences. Pet owners can rest assured
                    knowing their beloved companions are in good hands,
                    receiving the care and companionship they deserve while
                    they're busy or at work.
                  </p>
                  <h4>Services</h4>
                  <ul>
                    {dayCareServices.map((service, index) => (
                      <li key={index} className="serviceLi">
                        {service}
                      </li>
                    ))}
                  </ul>
                  <Button colorScheme="teal" size="md">
                    Book an appointment
                  </Button>
                </div>

                <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                  <Image src={dc} className="rounded" />
                </div>
              </CardBody>
            </Card>
          </div>
          <br />
          <div className="InfoCard">
            <Card backgroundColor="lightblue">
              <CardBody>
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          className="sessionTitle"
                        >
                          Basic Training for Levis
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <div className="d-flex justify-content-between p-1">
                        <p className="col-lg-9 upcomingEventCardClinicName">
                          <Unicons.UilBuilding className="d-inline mx-1" />
                          Service Center Name <br />
                          <Unicons.UilLocationPoint className="d-inline mx-1" />
                          Gachibowli, Hyderabad
                        </p>
                        <p className="col-lg-3">
                          <Unicons.UilCalender className="d-inline mx-1" />
                          06-04-2024 <br />
                          <Unicons.UilClock className="d-inline mx-1" />
                          06:00 PM{" "}
                        </p>
                      </div>
                      <button onClick={handleDownload}>Download PDF</button>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
