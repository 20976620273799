import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Checkbox,
  Divider,
  Heading,
  VStack,
  HStack,
  Spacer,
  Text,
} from "@chakra-ui/react";

const PetDiary = () => {
  const [activities, setActivities] = useState([
    {
      id: 1,
      time: "8:00 AM",
      activity: "Morning walk",
    },
    {
      id: 2,
      time: "12:00 PM",
      activity: "Playtime",
    },
    {
      id: 3,
      time: "6:00 PM",
      activity: "Feeding",
    },
  ]);

  const [newActivity, setNewActivity] = useState("");

  const handleAddActivity = () => {
    if (newActivity.trim() !== "") {
      const newId = activities.length + 1;
      const time = new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const activity = newActivity;
      setActivities([...activities, { id: newId, time, activity }]);
      setNewActivity("");
    }
  };

  return (
    <Box p={8} borderWidth="1px" borderRadius="lg">
      <Heading as="h2" size="lg" mb={4}>
        Pet Activities for Today
      </Heading>
      <VStack spacing={4} align="stretch">
        {activities.map((act) => (
          <Box key={act.id} borderWidth="1px" borderRadius="md" p={4}>
            <Heading as="h3" size="md" mb={2}>
              {act.time}
            </Heading>
            <Text>{act.activity}</Text>
          </Box>
        ))}
      </VStack>
      <Divider my={4} />
      <Stack spacing={4}>
        <FormControl id="newActivity">
          <FormLabel>Add New Activity</FormLabel>
          <HStack>
            <Input
              type="text"
              placeholder="Enter activity"
              value={newActivity}
              onChange={(e) => setNewActivity(e.target.value)}
            />
            <Spacer />
            <Button colorScheme="teal" onClick={handleAddActivity}>
              Add
            </Button>
          </HStack>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default PetDiary;
