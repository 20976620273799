import React from "react";
import "./Contact.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Stack,
  Heading,
  Text,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";
import * as Unicons from "@iconscout/react-unicons";

const ContactUs = () => {
  return (
    <>
      <Card className="contactBg">
        <Card className="formCard"></Card>
      </Card>
    </>
  );
};

export default ContactUs;
