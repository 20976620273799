import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export default function TopServices() {
  const [options, setOptions] = useState(null);
  const [series, setSeries] = useState(null);

  useEffect(() => {
    const topServicesData = [
      { name: "Haircut", data: [50, 60, 55, 65] },
      { name: "Nail Trimming", data: [30, 35, 40, 45] },
      { name: "Bathing", data: [20, 25, 30, 35] },
      { name: "Ear Cleaning", data: [10, 15, 20, 25] },
    ];

    setOptions({
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr"],
      },
      dataLabels: {
        enabled: false,
      },
    });

    setSeries(topServicesData);
  }, []);

  return (
    <>
      <h6>Top Services</h6>
      {options && series && (
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          width={250}
          height={200}
        />
      )}
    </>
  );
}
