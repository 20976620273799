import React from 'react';
import styles from './BookingSteps.module.css';

const StepCard = ({ icon, title, description }) => (
  <div className={styles.stepCard}>
    <div className={styles.stepCardIconContainer}><img loading="lazy" src={icon} alt="" className={styles.stepIcon} /></div>
    <h3 className={styles.stepTitle}>{title}</h3>
    <p className={styles.stepDescription}>{description}</p>
  </div>
);

const BookingSteps = () => (
  <section className={styles.bookingSteps}>
    <h2 className={styles.sectionTitle}>Quick & Easy Booking in <br />3 Simple Steps!</h2>
    <div className={styles.stepsContainer}>
      <StepCard
        icon="https://cdn.builder.io/api/v1/image/assets/TEMP/13c3d4ea48fc37a7eae0d717d96dbaf364a5d465624b941b0fd8bfdb1b802750?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
        title="Select Service"
        description="Read verified reviews by pet owners like you and choose a sitter who's a great."
      />
      <StepCard
        icon="https://cdn.builder.io/api/v1/image/assets/TEMP/d381dd7b315f42ddae09f7a87322026d26599ec56d318c0ef2105a66440cefe7?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
        title="Book Your Day"
        description="Read verified reviews by pet owners like you and choose a sitter who's a great."
      />
      <StepCard
        icon="https://cdn.builder.io/api/v1/image/assets/TEMP/deb2e909054e8e1e84ac00675d831616c50afc2253f3d7e04a4913e341d498dc?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
        title="Have Relax"
        description="Read verified reviews by pet owners like you and choose a sitter who's a great."
      />
    </div>
    <button className={styles.bookAppointmentButton}>Book an Appointment</button>
  </section>
);

export default BookingSteps;