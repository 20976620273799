import React, { useState } from "react";
import Logo from "../../assets/images/ZAANVAR_FINAL LOGO.png";
import { Outlet, NavLink, Link } from "react-router-dom";
import "./Navbar.css";
import Hero from "../Hero/Hero";
// import ECommerce from "../../assets/images/E-Commerce.jpg";
// import PetAnimalMarketing from "../../assets/images/Pet & Animal Marketing.jpg";
// import Clinics from "../../assets/images/Clinics.jpeg";
// import Adoption from "../../assets/images/Adoption.jpg";
// import Grooming from "../../assets/images/Grooming.jpeg";
// import PetAnimalNGOs  from "../../assets/images/Pet & Animal NGOs.jpg";
// import Events from "../../assets/images/Events.jpg";
// import PetBreeds from "../../assets/images/Pet Breeds.jpg";
// import Location from "../../assets/images/Location.png";
// import EReport from "../../assets/images/E-Report.png";
// import Ride from "../../assets/images/Ride.jpeg";
// import PetDiary from "../../assets/images/Pet Diary.jpeg";
// import FoundMissing from "../../assets/images/Found & Missing.jpeg";
// import NutritionDiet from "../../assets/images/Nutrition Diet.jpg";
// import PetSitterWalker from "../../assets/images/Pet Sitter  Walker.jpeg";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";


const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); 

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const handleServicesClick = () => {
    setShowDropdown(showDropdown);
  };
 

  return (
    <>
    <Header/>
      <Hero/>
      <Footer/>
    </>
  );
};

export default Navbar;

  