import React, { useState } from 'react';
import ArrowIcon from '../icons/ArrowIcon';

const ServiceButton = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    // Handle the button click
    console.log('Button clicked!');
    setIsDisabled(true); // Disable the button after click
  };

  return (
    <button
      onClick={handleClick}
      disabled={isDisabled}
      style={{
        backgroundColor: '#eb7c24',
        color: 'white',
        border: 'none',
        padding: '20px 20px',
        fontSize: '16px',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      <ArrowIcon/>
    </button>
  );
};

export default ServiceButton;
