import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Select,
} from "@chakra-ui/react";
import sign from "./../../../../assets/service provider/Signature.png";
import { UilTrashAlt } from "@iconscout/react-unicons";

const InvoiceStructureMaking = () => {
  const [customerName, setCustomerName] = useState("");
  const [showPDF, setShowPDF] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const [formData, setFormData] = useState({
    customerName: "",
    petName: "",
    totalAmount: "",
    invoiceNumber: "",
    clientAddress: "",
    clientPhoneNumber: "",
    date: "",
    services: [],
  });

  const handleAddService = (selectedSer) => {
    setSelectedService(selectedSer);

    const [serviceName, amount] = selectedService.split(" - ");
    const service = { name: serviceName, amount: parseInt(amount) };
    if (serviceName !== "") {
      setSelectedServices([...selectedServices, service]);
      setTotalAmount(totalAmount + parseInt(amount));
    }
    setFormData({ ...formData, totalAmount: totalAmount });
    console.log("selectedServices", selectedServices);
    console.log("selectedServices", totalAmount);
  };

  useEffect(() => {
    console.log("selectedServices", selectedService);
    console.log("selectedServices", selectedServices);
    console.log("selectedServices", totalAmount);
  }, [selectedServices, totalAmount, formData]);

  const deleteSelectedItem = (index) => {
    console.log("Deleting item at index:", index);
    const updatedServices = [...selectedServices];
    updatedServices.splice(index, 1);
    setSelectedServices(updatedServices);
  };

  const handleSave = () => {
    setShowPDF(true);
  };

  const handleDownload = () => {
    setShowPDF(false);
  };

  const handleInputChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const defaultServices = [
    "Nail Cutting - 300",
    "Bathing - 500",
    "Hair Trimming - 400",
    "Ear Cleaning - 200",
    "Teeth Brushing - 150",
    "Anal Gland Expression - 250",
    "Paw Pad Trimming - 200",
    "Flea and Tick Treatment - 350",
    "De-shedding Treatment - 450",
    "Full Grooming Package - 1000",
  ];

  const convertToWords = (num) => {
    const ones = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    const teens = [
      "",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tens = [
      "",
      "ten",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const denominations = ["", "thousand", "lakh", "crore"];

    const convert = (num) => {
      if (num === 0) return "";

      let str = "";
      if (num >= 10000000) {
        str +=
          convert(Math.floor(num / 10000000)) + " " + denominations[3] + " ";
        num %= 10000000;
      }
      if (num >= 100000) {
        str += convert(Math.floor(num / 100000)) + " " + denominations[2] + " ";
        num %= 100000;
      }
      if (num >= 1000) {
        str += convert(Math.floor(num / 1000)) + " " + denominations[1] + " ";
        num %= 1000;
      }
      if (num >= 100) {
        str += ones[Math.floor(num / 100)] + " hundred ";
        num %= 100;
      }
      if (num >= 10 && num <= 19) {
        str += teens[num - 10];
        num = 0;
      } else if (num >= 20) {
        str += tens[Math.floor(num / 10)];
        num %= 10;
      }
      if (num > 0) {
        str += " " + ones[num];
      }
      return str.trim();
    };

    let words = convert(num);
    if (words === "") return "zero";
    return words + " rupees only";
  };

  return (
    <VStack spacing={8} alignItems="flex-start">
      {!showPDF ? (
        <FormControl className="d-flex flex-column">
          <div
            className="d-flex flex-row justify-content-center"
            style={{ gap: "2rem" }}
          >
            <div className="d-flex flex-column w-50" style={{ gap: ".4rem" }}>
              <FormLabel>Customer Name</FormLabel>
              <Input
                type="text"
                value={formData.customerName}
                onChange={(e) =>
                  handleInputChange("customerName", e.target.value)
                }
              />
              <FormLabel>Pet Name</FormLabel>
              <Input
                type="text"
                value={formData.petName}
                onChange={(e) => handleInputChange("petName", e.target.value)}
              />
              <FormLabel>Total Amount</FormLabel>
              <Input
                type="number"
                value={formData.totalAmount}
                onChange={(e) =>
                  handleInputChange("totalAmount", e.target.value)
                }
                disabled
              />
              <FormLabel>Invoice Number</FormLabel>
              <Input
                type="text"
                value={formData.invoiceNumber}
                onChange={(e) =>
                  handleInputChange("invoiceNumber", e.target.value)
                }
              />
            </div>
            <div className="d-flex flex-column w-50" style={{ gap: ".4rem" }}>
              <FormLabel>Client Address</FormLabel>
              <Input
                type="text"
                value={formData.clientAddress}
                onChange={(e) =>
                  handleInputChange("clientAddress", e.target.value)
                }
              />
              <FormLabel>Client Phone Number</FormLabel>
              <Input
                type="Number"
                value={formData.clientPhoneNumber}
                maxLength={10}
                onChange={(e) =>
                  handleInputChange("clientPhoneNumber", e.target.value)
                }
              />
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                value={formData.date}
                onChange={(e) => handleInputChange("date", e.target.value)}
              />

              <FormLabel>Selected Services</FormLabel>
              <div style={{ maxHeight: "75px", overflowY: "scroll" }}>
                {selectedServices.map((service, index) => (
                  <p key={index} className="d-flex justify-content-between">
                    {service.name} - {service.amount}
                    {<UilTrashAlt onClick={() => deleteSelectedItem(index)} />}
                  </p>
                ))}
              </div>

              <Select
                placeholder="Select Service"
                value={selectedService}
                onChange={(e) => handleAddService(e.target.value)}
              >
                {defaultServices.map((service, index) => (
                  <option key={index} value={service}>
                    {service}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <Button colorScheme="blue" className="my-3" onClick={handleSave}>
            Save
          </Button>
        </FormControl>
      ) : (
        <PDFViewer width="1000" height="600">
          <Document>
            <Page size="A4">
              <View style={styles.page}>
                <View style={styles.header} fixed>
                  <Text style={styles.headerText}>
                    Scooby Doo Company & Co.
                  </Text>
                </View>
                <View style={styles.section}>
                  <View style={styles.invoiceHeader}>
                    <Text style={styles.invoiceHeaderText}>Invoice</Text>
                  </View>
                  <View style={styles.nameSection}>
                    <View style={styles.invoiceInfo}>
                      <Text>Customer Name: {customerName}</Text>
                      <Text>Pet Name: Jublie</Text>
                      <Text>Total Amount: {totalAmount}</Text>
                    </View>
                    <View style={styles.contactInfo}>
                      <Text style={styles.contactInfoText}>+91- 65656</Text>
                      <Text style={styles.contactInfoText}>abc@gmail.com</Text>
                      <Text style={styles.contactInfoText}>Tax ID:123</Text>
                      <Text style={styles.contactInfoText}>GSTIN : 456</Text>
                      <Text style={styles.contactInfoText}>
                        NRIC/FIN : A789
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text>Invoice: FV 00001/09/2024</Text>
                    <Text>Client Address: Gachibowli Hyderabad</Text>
                    <Text>Date: 25 April, 2024.</Text>
                  </View>
                  <View style={styles.line} />
                  <view style={styles.itemsSection}>
                    <view style={styles.itemsSectionTitle}>
                      <Text>Description</Text>
                      <Text>Total</Text>
                    </view>
                    <view style={styles.itemsSectionList}>
                      <Text>Nail Cutting</Text>
                      <Text>300/-</Text>
                    </view>
                    <view style={styles.itemsSectionList}>
                      <Text>Bathing</Text>
                      <Text>800/-</Text>
                    </view>
                  </view>
                  <View style={styles.dottedLine} />
                  <view style={styles.gstSection}>
                    <view style={styles.cgstLine}>
                      <Text>CGST @ 9%</Text>
                      <Text>99/-</Text>
                    </view>
                    <view style={styles.sgstLine}>
                      <Text>SGST @ 9%</Text>
                      <Text>99/-</Text>
                    </view>
                  </view>
                  <View style={styles.dottedLine} />
                  <view style={styles.amountTotal}>
                    <Text>Total</Text>
                    <Text>1298/-</Text>
                  </view>
                  <Text style={styles.amountInWords}>
                    One thousand two hundred and ninty eight rupees only
                  </Text>
                  <View style={styles.line} />
                  <view style={styles.signSection}>
                    <Image source={sign} style={styles.signatureImage} />
                    <Text>Ranjith (Co-Founder of Scooby-Doo)</Text>
                    <Text>GST will be paid by me</Text>
                  </view>
                </View>

                <View style={styles.footer} fixed>
                  <Text style={styles.footerText}>Gachibowli, Hyderabad</Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
      {showPDF && (
        <Button colorScheme="green" onClick={handleDownload}>
          Download PDF
        </Button>
      )}
    </VStack>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
    position: "relative",
    flexGrow: 1,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  invoiceHeader: {
    marginBottom: 10,
  },
  invoiceHeaderText: {
    fontWeight: "bold",
    fontSize: 20,
  },
  nameSection: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  invoiceInfo: {
    width: "50%",
  },
  contactInfo: {
    width: "50%",
  },
  contactInfoText: {
    textAlign: "right",
  },
  invoiceSection: {
    textAlign: "left",
  },
  line: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    marginVertical: 10,
  },
  dottedLine: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    marginVertical: 10,
    borderStyle: "dotted",
  },
  itemsSection: { flexDirection: "column" },
  itemsSectionTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: "5px",
    marginBottom: "16px",
  },
  itemsSectionList: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cgstLine: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sgstLine: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  amountTotal: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  amountInWords: {
    textAlign: "right",
    fontSize: "8px",
  },
  signatureImage: {
    width: 100,
    height: 50,
  },
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    paddingVertical: 10,
    backgroundColor: "#eee",
  },
  headerText: {
    fontSize: 16,
    fontWeight: "bold",
  },

  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    paddingVertical: 10,
    backgroundColor: "#eee",
  },
  footerText: {
    fontSize: 12,
  },
});
export default InvoiceStructureMaking;
