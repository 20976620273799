import React from "react";
import GroomingServiceNavbar from "./Components/Dashboard/GroomingServiceNavbar";
import GroomingServieSideNav from "./Components/Dashboard/GroomingServiceSideNav";
import AppointmentTable from "./Components/AppointmentsSP/AppointmentTable";
import InvoiceGenerator from "./Components/AppointmentsSP/InvoiceGenerator";
// import GroomingChartsSection from "./Components/Dashboard/GroomingChartsSection";
// import GroomingDashboardTable from "./Components/Dashboard/GroomingDashboardTable";

export default function TotalAppointmentsSP() {
  return (
    <>
      <div className="h-100">
        <GroomingServiceNavbar />
        <div className="d-flex">
          <GroomingServieSideNav />
          <AppointmentTable />
          {/* <InvoiceGenerator /> */}
        </div>
      </div>
    </>
  );
}
