import React from 'react';
import styles from './Services.module.css';
import ServiceButton from './ServiceButton';

const ServiceCard = ({ image, title, description, showButton, height }) => (
  <div className={styles.serviceCard} style={{ height: height || 'auto' }}>
    <img loading="lazy" src={image} alt={title} className={styles.serviceImage} />
    {showButton && (
      <div className={styles.serviceButton}>
        <ServiceButton />
      </div>
    )}
    <div className={styles.serviceContent}>
      <h3 className={styles.serviceTitle}>{title}</h3>
      <p className={styles.serviceDescription}>{description}</p>
      <button className={styles.readMoreButton}>Read more</button>
    </div>
  </div>
);

export default ServiceCard;
