import { Card, CardBody } from "@chakra-ui/react";
// import TopServices from "./ChartComponents/TopServices";
// import AppointmentDistribution from "./ChartComponents/AppointmentDistribution";
// import MonthlySales from "./ChartComponents/MonthlySales";
// import UpcomingLeaves from "./ChartComponents/UpcomingLeaves";
import * as Unicons from "@iconscout/react-unicons";
import "./../../styles/InventoryDashboard.css";

export default function InventoryChartsSection() {
  return (
    <>
      <Card width={"80vw"} bg={"#005555"}>
        <CardBody>
          <div className="graphSection">
            <Card className="item">
              <CardBody>
                <div
                  style={{
                    color: "red",
                    display: "flex",
                    fontSize: "1rem",
                    fontWeight: "700",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Unicons.UilExclamationTriangle /> Products out of stock - 30
                </div>
              </CardBody>
            </Card>
            <Card className="item">
              <CardBody>
                {" "}
                <div
                  style={{
                    color: "blue",
                    display: "flex",
                    fontSize: "1rem",
                    fontWeight: "700",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Unicons.UilEnvelopeExclamation /> Products on low Stock - 40
                </div>
              </CardBody>
            </Card>
            <Card className="item">
              <CardBody>
                {" "}
                <div
                  style={{
                    color: "green",
                    display: "flex",
                    fontSize: "1rem",
                    fontWeight: "700",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Unicons.UilTruck /> Products to be arrived - 50
                </div>
              </CardBody>
            </Card>
            <Card className="item">
              <CardBody>
                {" "}
                <div
                  style={{
                    color: "orange",
                    display: "flex",
                    fontSize: "1rem",
                    fontWeight: "700",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Unicons.UilHourglass /> Products getting expired - 60
                </div>
              </CardBody>
            </Card>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
