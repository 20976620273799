import React from 'react';
import styles from './Footer.module.css';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footerContent}>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/78f832d72f9f367efc0ff0882e19b65026091938beafa18c8686f7b4126d4b25?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="Logo" className={styles.footerLogo} />
      <nav className={styles.footerNav}>
        <a href="#home" className={styles.footerLink}>Home</a>
        <a href="#services" className={styles.footerLink}>Services</a>
        <a href="#book-vet" className={styles.footerLink}>Book a Vet</a>
        <a href="#shopping" className={styles.footerLink}>Shopping</a>
        <a href="#about" className={styles.footerLink}>About us</a>
      </nav>
      <div className={styles.socialLinks}>
        <a href="#" aria-label="Facebook"><img src="https://cdn.builder.io/api/v1/image/assets/TEMP/ba7884bde25bf0dfd146a8050c79764f13b338aefa372c70618b7711094f0e8a?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="" className={styles.socialIcon} /></a>
        <a href="#" aria-label="Twitter"><img src="https://cdn.builder.io/api/v1/image/assets/TEMP/995aa139b3143c5f3bf50c2fb4d2127534320761d0fdb0e24f2fee9bd742d450?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="" className={styles.socialIcon} /></a>
        <a href="#" aria-label="Instagram"><img src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f834d2a593e4f9bb766085ed949e9f5b939d223910580cb67714670e4ca4990?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="" className={styles.socialIcon} /></a>
        <a href="#" aria-label="LinkedIn"><img src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6020af5b08200c2bae4af08d24b75a0d7ea8e6902580faeebe07c879fdf7333?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="" className={styles.socialIcon} /></a>
      </div>
    </div>
    <div className={styles.footerDivider} />
    <p className={styles.footerCopyright}>© 2024 zaanvar.in | All Rights Reserved</p>
  </footer>
);

export default Footer;