import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Text,
  Select,
  Button,
  Progress,
  Input,
} from "@chakra-ui/react";
import * as Unicons from "@iconscout/react-unicons";
import "./../../styles/GroomingLeavePage.css";

export default function ApplyLeave() {
  const [holidaysTaken, setHolidaysTaken] = useState(0);
  const [leavesTaken, setLeavesTaken] = useState(0);
  const [upcomingLeaves, setUpcomingLeaves] = useState([]);
  const [leaveType, setLeaveType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalDays, setTotalDays] = useState(0);
  const [reason, setReason] = useState("");

  useEffect(() => {
    console.log(leaveType);
  }, [leaveType]);

  const handleFromDateChange = (e) => {
    const selectedFromDate = new Date(e.target.value);
    const selectedToDate = new Date(toDate);
    const differenceInTime =
      selectedToDate.getTime() - selectedFromDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    setFromDate(e.target.value);
    setTotalDays(differenceInDays);
  };

  const handleToDateChange = (e) => {
    const selectedFromDate = new Date(fromDate);
    const selectedToDate = new Date(e.target.value);
    const differenceInTime =
      selectedToDate.getTime() - selectedFromDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    setToDate(e.target.value);
    setTotalDays(differenceInDays);
  };

  const handleApplyLeave = () => {
    if (leaveType === "") {
      alert("Please select a leave type.");
      return;
    }

    const newLeave = {
      id: upcomingLeaves.length + 1,
      reason: reason,
      leaveType: leaveType,
      fromDate: fromDate,
      toDate: toDate,
      totalDays: totalDays,
    };
    setUpcomingLeaves([...upcomingLeaves, newLeave]);

    handleCancel();

    if (leaveType === "Leave") {
      setLeavesTaken(leavesTaken + totalDays);
    } else if (leaveType === "Holiday") {
      setHolidaysTaken(holidaysTaken + totalDays);
    }
  };

  const handleCancel = () => {
    setFromDate("");
    setToDate("");
    setTotalDays(0);
    setReason("");
    setLeaveType("");
  };

  const handleDeleteLeave = (id) => {
    const updatedLeaves = upcomingLeaves.filter((leave) => leave.id !== id);
    setUpcomingLeaves(updatedLeaves);
  };

  return (
    <Card width={"80.5vw"} height={"75vh"}>
      <CardBody>
        <div className="d-flex">
          <div className="holidaysCard">
            <h4>Holidays</h4>
            <h6>{holidaysTaken}/15 (No of days)</h6>
            <Progress
              value={(holidaysTaken / 15) * 100}
              size="xs"
              colorScheme={(holidaysTaken / 15) * 100 < 100 ? "blue" : "red"}
            />
          </div>
          <div className="holidaysCard">
            <h4>Leaves</h4>
            <h6>{leavesTaken}/15 (No of days)</h6>
            <Progress
              value={(leavesTaken / 15) * 100}
              size="xs"
              colorScheme={(leavesTaken / 15) * 100 < 100 ? "blue" : "red"}
            />
          </div>
        </div>

        <h4>Upcoming Leaves</h4>
        <Card
          style={{ height: "5rem", overflowY: "scroll" }}
          className="leaveCard"
        >
          <CardBody padding={"5px"}>
            {upcomingLeaves.length > 0 ? (
              upcomingLeaves.map((leave, index) => (
                <Card
                  key={index}
                  height={"2rem"}
                  padding={"5px"}
                  marginBottom={".4rem"}
                  bg={index % 2 === 0 ? "lightblue" : "lightgoldenrodyellow"}
                >
                  <CardBody padding={"0px"}>
                    <div className="leavesList d-flex align-items-center justify-content-between">
                      <Text>{leave.reason}</Text>
                      <Text>{leave.fromDate}</Text>
                      <Text>{leave.toDate}</Text>
                      <Text>{leave.totalDays}</Text>
                      <Text>{leave.leaveType}</Text>
                      <Unicons.UilTrash
                        onClick={() => handleDeleteLeave(leave.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </CardBody>
                </Card>
              ))
            ) : (
              <p className="text-center display-6">
                No upcoming Leaves or Holidays
              </p>
            )}
          </CardBody>
        </Card>

        <h4>Apply New Leave</h4>
        <Card>
          <CardBody padding={".5rem"}>
            <h5>Reason</h5>
            <Input
              placeholder="Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            <h5>Leave Type</h5>

            <Select
              placeholder="Select leave type"
              value={leaveType}
              onChange={(e) => setLeaveType(e.target.value)}
            >
              <option value="Leave">Leave</option>
              <option value="Holiday">Holiday</option>
            </Select>
            <h5>Dates</h5>

            <div className="leaveDates d-flex justify-content-between">
              <div className="d-flex ">
                <div className="d-flex flex-column dateList">
                  <label>From Date</label>
                  <input
                    type="date"
                    name="fromDate"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    min={new Date()}
                  />
                </div>
                <div className="d-flex flex-column dateList">
                  <label>To Date</label>
                  <input
                    type="date"
                    name="toDate"
                    value={toDate}
                    onChange={handleToDateChange}
                  />
                </div>
                <div className="d-flex flex-column dateList">
                  <label>Total Days</label>
                  <input type="text" value={totalDays} disabled />
                </div>
              </div>

              <div className="buttonsSection my-4">
                <Button
                  colorScheme="messenger"
                  onClick={handleApplyLeave}
                  className="mx-4"
                >
                  Apply
                </Button>
                <Button colorScheme="red" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
}
