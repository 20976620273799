import React, { useState } from "react";
import {
  Flex,
  Box,
  Button,
  Image,
  Text,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Card,
  CardBody,
} from "@chakra-ui/react";
import CustomerNavbar from "./Components/CustomerNavbar";

const MyPets = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [newPet, setNewPet] = useState({
    name: "",
    breed: "",
    age: "",
    weight: "",
    color: "",
    imageUrl: "",
    vaccinationReport: "",
    healthHistory: "",
    otherDocuments: "",
  });

  const [petsData, setPetsData] = useState([
    {
      id: 1,
      name: "Buddy",
      breed: "Golden Retriever",
      age: 3,
      weight: 25,
      color: "Golden",
      imageUrl: "https://via.placeholder.com/150",
      vaccinationReport: "",
      healthHistory: "",
      otherDocuments: "",
    },
    {
      id: 2,
      name: "Max",
      breed: "Labrador",
      age: 2,
      weight: 30,
      color: "Black",
      imageUrl: "https://via.placeholder.com/150",
      vaccinationReport: "",
      healthHistory: "",
      otherDocuments: "",
    },
  ]);

  const [selectedPet, setSelectedPet] = useState(null);
  const [showPetModal, setShowPetModal] = useState(false);

  const handleAddNewPet = () => {
    setShowAddModal(true);
  };

  const handleSaveNewPet = () => {
    const newPetWithId = { ...newPet, id: petsData.length + 1 };
    const updatedPetsData = [...petsData, newPetWithId];

    setPetsData(updatedPetsData);

    setShowAddModal(false);
  };

  const handlePetCardClick = (pet) => {
    setSelectedPet(pet);
    setShowPetModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setNewPet((prevState) => ({
        ...prevState,
        imageUrl: reader.result,
      }));
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="CustomerDashboard">
      <div className="CustomerNavbar">
        <Card  width="100%">
          <CardBody>
            <CustomerNavbar />
          </CardBody>
        </Card>
      </div>
      <Flex direction="column" alignItems="center" mt={8}>
        <Box>
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            My Pets
          </Text>
          <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={6}>
            {petsData.map((pet) => (
              <GridItem key={pet.id}>
                <Box
                  p={4}
                  border="1px solid #E2E8F0"
                  borderRadius="md"
                  cursor="pointer"
                  onClick={() => handlePetCardClick(pet)}
                >
                  <Flex justify="space-between" align="center" mb={2}>
                    <Text fontWeight="bold">{pet.name}</Text>
                    <Text>{pet.breed}</Text>
                  </Flex>
                  <Flex align="center">
                    <Box mr={4}>
                      <Image
                        src={pet.imageUrl}
                        alt={pet.name}
                        borderRadius="full"
                        boxSize="50px"
                      />
                    </Box>
                    <Text>{pet.age} years old</Text>
                  </Flex>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Box>

        <Button mt={4} colorScheme="teal" onClick={handleAddNewPet}>
          Add New Pet
        </Button>

        <Modal isOpen={showAddModal} onClose={() => setShowAddModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Pet</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Pet Name</FormLabel>
                <Input
                  name="name"
                  value={newPet.name}
                  onChange={handleInputChange}
                />
              </FormControl>
              <Stack direction="row" spacing={4}>
                <FormControl>
                  <FormLabel>Pet Breed</FormLabel>
                  <Input
                    name="breed"
                    value={newPet.breed}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Pet Age</FormLabel>
                  <Input
                    name="age"
                    value={newPet.age}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={4}>
                <FormControl>
                  <FormLabel>Pet Weight</FormLabel>
                  <Input
                    name="weight"
                    value={newPet.weight}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Pet Color</FormLabel>
                  <Input
                    name="color"
                    value={newPet.color}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Stack>
              <FormControl mt={4}>
                <FormLabel>Pet Image</FormLabel>
                <Input type="file" onChange={handleFileUpload} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Vaccination Report</FormLabel>
                <Input
                  name="vaccinationReport"
                  value={newPet.vaccinationReport}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Health History</FormLabel>
                <Textarea
                  name="healthHistory"
                  value={newPet.healthHistory}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Other Documents</FormLabel>
                <Input
                  name="otherDocuments"
                  value={newPet.otherDocuments}
                  onChange={handleInputChange}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={handleSaveNewPet}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={showPetModal} onClose={() => setShowPetModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedPet && selectedPet.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  Breed:
                </Text>
                <Text>{selectedPet && selectedPet.breed}</Text>
              </Box>
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Age:
                </Text>
                <Text>{selectedPet && selectedPet.age} years</Text>
              </Box>
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Weight:
                </Text>
                <Text>{selectedPet && selectedPet.weight} kg</Text>
              </Box>
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Color:
                </Text>
                <Text>{selectedPet && selectedPet.color}</Text>
              </Box>
              <Box mt={4}>
                <Image
                  src={selectedPet && selectedPet.imageUrl}
                  alt={selectedPet && selectedPet.name}
                  borderRadius="full"
                  boxSize="150px"
                />
              </Box>
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Vaccination Report:
                </Text>
                <Text>{selectedPet && selectedPet.vaccinationReport}</Text>
              </Box>
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Health History:
                </Text>
                <Text>{selectedPet && selectedPet.healthHistory}</Text>
              </Box>
              <Box mt={4}>
                <Text fontSize="lg" fontWeight="bold">
                  Other Documents:
                </Text>
                <Text>{selectedPet && selectedPet.otherDocuments}</Text>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </div>
  );
};

export default MyPets;
