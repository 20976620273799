import React, { useState } from "react";
import { Flex, Input, Button, Card, CardBody, Text } from "@chakra-ui/react";
import CustomerNavbar from "../CustomerNavbar";

export default function PetPedia() {
  const [searchValue, setSearchValue] = useState("");
  const [searchedValue, setSearchedValue] = useState("");

  const handleSearch = () => {
    setSearchedValue(searchValue);
  };

  return (
    <div className="CustomerDashboard">
      <div className="CustomerNavbar">
        <Card  width="100%">
          <CardBody>
            <CustomerNavbar />
          </CardBody>
        </Card>
      </div>
      <Flex direction="column" alignItems="center" mt={8}>
        <Flex mb={4}>
          <Input
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            mr={2}
          />
          <Button colorScheme="teal" onClick={handleSearch}>
            Search
          </Button>
        </Flex>

        {searchedValue && (
          <Card width="80%">
            <CardBody>
              <Text fontSize="xl">Searched Value: {searchedValue}</Text>
            </CardBody>
          </Card>
        )}
      </Flex>
    </div>
  );
}
