import React from "react";
import GroomingServiceNavbar from "./Components/Dashboard/GroomingServiceNavbar";
import GroomingServieSideNav from "./Components/Dashboard/GroomingServiceSideNav";
import AllInvoicesCard from "./Components/AllInvoices/AllInvoicesCard";

export default function InvoiceSearch() {
  return (
    <>
      <div className="h-100">
        <GroomingServiceNavbar />
        <div className="d-flex">
          <GroomingServieSideNav />
          <AllInvoicesCard />
        </div>
      </div>
    </>
  );
}
