import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  CardBody,
  Card,
  TableContainer,
} from "@chakra-ui/react";

const MyPatientsTable = () => {
  const data = [
    {
      id: 1,
      name: "John Doe",
      petName: "Buddy",
      petType: "Dog",
      petAge: 3,
      appointmentDate: "2024-05-10",
      appointmentFor: "Checkup",
    },
    {
      id: 2,
      name: "Jane Smith",
      petName: "Whiskers",
      petType: "Cat",
      petAge: 5,
      appointmentDate: "2024-05-12",
      appointmentFor: "Vaccination",
    },
    {
      id: 3,
      name: "Alice Johnson",
      petName: "Spot",
      petType: "Dog",
      petAge: 2,
      appointmentDate: "2024-05-11",
      appointmentFor: "Grooming",
    },
    {
      id: 4,
      name: "Bob Brown",
      petName: "Fluffy",
      petType: "Rabbit",
      petAge: 1,
      appointmentDate: "2024-05-13",
      appointmentFor: "Checkup",
    },
    {
      id: 5,
      name: "Emma Davis",
      petName: "Max",
      petType: "Dog",
      petAge: 4,
      appointmentDate: "2024-05-15",
      appointmentFor: "Vaccination",
    },
    {
      id: 6,
      name: "Michael Wilson",
      petName: "Mittens",
      petType: "Cat",
      petAge: 7,
      appointmentDate: "2024-05-16",
      appointmentFor: "Checkup",
    },
    {
      id: 7,
      name: "Olivia Taylor",
      petName: "Rocky",
      petType: "Hamster",
      petAge: 1,
      appointmentDate: "2024-05-17",
      appointmentFor: "Vaccination",
    },
    {
      id: 8,
      name: "William Martinez",
      petName: "Luna",
      petType: "Dog",
      petAge: 2,
      appointmentDate: "2024-05-18",
      appointmentFor: "Checkup",
    },
    {
      id: 9,
      name: "Sophia Anderson",
      petName: "Oreo",
      petType: "Cat",
      petAge: 6,
      appointmentDate: "2024-05-20",
      appointmentFor: "Grooming",
    },
    {
      id: 10,
      name: "Daniel Garcia",
      petName: "Toby",
      petType: "Dog",
      petAge: 5,
      appointmentDate: "2024-05-22",
      appointmentFor: "Vaccination",
    },
  ];

  return (
    <Card>
      <CardBody padding={".4rem"}>
        <h4>My Patients</h4>
        <TableContainer
          style={{ maxHeight: "175px", overflowY: "scroll" }}
          className="patientTableScrollStyle"
        >
          <Table variant="simple" bg="#D6E4E5" colorScheme="gray">
            <Thead bg="#497174" style={{ color: "white" }}>
              <Tr>
                <Th className="tableTitles">Id</Th>
                <Th className="tableTitles">Name</Th>
                <Th className="tableTitles">Pet Name</Th>
                <Th className="tableTitles">Pet Type</Th>
                <Th className="tableTitles">Pet Age</Th>
                <Th className="tableTitles">Appointment Date</Th>
                <Th className="tableTitles">Appointment For</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((record) => (
                <Tr key={record.id}>
                  <Td>{record.id}</Td>
                  <Td>{record.name}</Td>
                  <Td>{record.petName}</Td>
                  <Td>{record.petType}</Td>
                  <Td>{record.petAge}</Td>
                  <Td>{record.appointmentDate}</Td>
                  <Td>{record.appointmentFor}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default MyPatientsTable;
