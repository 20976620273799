import "./../../Styles/DoctorDashboard.css";
import * as Unicons from "@iconscout/react-unicons";
import { Card, CardBody, Wrap, WrapItem, Avatar } from "@chakra-ui/react";
import PatientOverview from "./Chart/PatientOverview";
import MyPatientsTable from "./MyPatientsTable";
import DailyVisitors from "./Chart/DailyVisitors";
import AppointmentStats from "./Chart/AppointmentStats";

export default function DoctorDashboardBody() {
  const AppointmentsData = Array.from({ length: 9 }, (_, index) => ({
    id: index + 1,
    petName: `Pet ${index + 1}`,
    appointmentFor: `Appointment For ${index + 1}`,
    appointmentTime: `10:00 AM`,
  }));
  return (
    <>
      <div className="d-flex">
        <div className="overviewSection">
          <div className="d-flex p-3">
            <div className="statsSection">
              <Card className="statItem">
                <CardBody padding={".4rem"}>
                  <Unicons.UilUserNurse
                    className="statImage"
                    style={{ background: "#4da8fd" }}
                  />
                  <h3>1450</h3>
                  <p className="subTitle">
                    Total Patients <span className="statPercent">+0.39%</span>
                  </p>
                </CardBody>
              </Card>
              <Card className="statItem">
                <CardBody padding={".4rem"}>
                  <Unicons.UilUserPlus
                    className="statImage"
                    style={{ background: "#423efe" }}
                  />
                  <h3>63</h3>
                  <p className="subTitle">
                    New Patients <span className="statPercent">+0.62%</span>
                  </p>
                </CardBody>
              </Card>
              <Card className="statItem">
                <CardBody padding={".4rem"}>
                  <Unicons.UilBed
                    className="statImage"
                    style={{ background: "#6cbf66" }}
                  />
                  <h3>313</h3>
                  <p className="subTitle">
                    Old Patients <span className="statPercent neg">-0.12%</span>
                  </p>
                </CardBody>
              </Card>
              <Card className="statItem">
                <CardBody padding={".4rem"}>
                  <Unicons.UilCalendarAlt
                    className="statImage"
                    style={{ background: "#ff833d" }}
                  />
                  <h3>1971</h3>
                  <p className="subTitle">
                    Appointments <span className="statPercent">+2.9%</span>
                  </p>
                </CardBody>
              </Card>
            </div>
            <div className="chartSection">
              <Card>
                <CardBody>
                  <h4>Patients Overview</h4>
                  <PatientOverview />
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="PatientTable p-3">
            <MyPatientsTable />
          </div>
          <div className="overviewSection2 p-3">
            <div className="visitorsSection">
              <DailyVisitors />
            </div>
            <div className="appointmentStatsSection">
              <AppointmentStats />
            </div>
          </div>
        </div>
        <div className="appointmentsSection">
          <h4>Appointments</h4>
          {AppointmentsData.map((record) => (
            <Card className="my-1" bg={"#EFF5F5"}>
              <CardBody className="d-flex" gap={".75rem"}>
                <div>
                  <Wrap>
                    <WrapItem>
                      <Avatar
                        name="Dan Abrahmov"
                        src="https://bit.ly/dan-abramov"
                      />
                    </WrapItem>
                  </Wrap>
                </div>
                <div>
                  <p style={{ marginBottom: "0" }}>{record.petName}</p>
                  <p style={{ marginBottom: "0" }}>{record.appointmentFor}</p>
                  <p style={{ marginBottom: "0" }}>{record.appointmentTime}</p>
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
}
