import React from "react";
import calendar from "../../../../../assets/service provider/Calendar.png";

const UpcomingLeaves = () => {
  const upcomingLeaves = [
    { date: "2024-04-25", description: "Annual Leave" },
    { date: "2024-05-10", description: "Sick Leave" },
    { date: "2024-05-28", description: "Vacation" },
  ];
  const currentDate = new Date();
  const day = currentDate.getDate() + 2;
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();
  return (
    <>
      <h6>Upcoming Leaves</h6>

      <div
        className="d-flex align-items-center justify-content-center"
        style={{ lineHeight: "50px", color: "#069A8E" }}
      >
        <div style={{ width: "50%", height: "100%" }}>
          <p style={{ fontSize: "7rem" }}>{day}</p>
        </div>
        <div style={{ width: "50%" }}>
          <p style={{ fontSize: "3rem", marginBottom: "0px" }}>{month}</p>
          <p style={{ fontSize: "3.25rem" }}>{year}</p>
        </div>
      </div>
      <div className="d-flex justify-content-end" style={{ bottom: "0" }}>
        <img src={calendar} alt="Calendar Icon" height={96} width={96} />
      </div>
    </>
  );
};

export default UpcomingLeaves;
