import React, { useState } from "react";
import Logo from "../../../assets/images/ZAANVAR_FINAL LOGO ICON.ico";
import { Outlet, NavLink, Link } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Divider,
  Button,
  Wrap,
  WrapItem,
  Avatar,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "./CustomerNavbar.css";

const CustomerNavbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [activeItem, setActiveItem] = useState("");

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const Hamburger = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="24"
      viewBox="0 0 52 24"
    >
      <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="42"
          height="4"
          rx="2"
          transform="translate(304 47)"
          fill="#574c4c"
        />
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="42"
          height="4"
          rx="2"
          transform="translate(304 67)"
          fill="#574c4c"
        />
        <rect
          id="Rectangle_4"
          data-name="Rectangle 4"
          width="52"
          height="4"
          rx="2"
          transform="translate(294 57)"
          fill="#574c4c"
        />
      </g>
    </svg>
  );

  return (
    <>
      <nav className="customerNavbar">
        <div className="container">
          <div className="logo">
            <img src={Logo} style={{ height: "85px", paddingTop: "8px" }} />
          </div>
          <div className="menu-icon" onClick={handleShowNavbar}>
            <Hamburger />
          </div>
          <div className={`nav-elements ${showNavbar ? "active": ""}`}>
            <ul>
              <li className={activeItem === "dashboard" ? "active-item" : ""}>
                <NavLink to="/" 
                exact
                onClick={() => handleItemClick("dashboard")}
                >
                  Dashboard
                </NavLink>
              </li>
              <li className={activeItem === "services" ? "active-item" : ""}>
                <NavLink to="/services" 
                 onClick={() => handleItemClick("services")}
                > 
                Services
                </NavLink>
                {/* <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    Services
                  </MenuButton>
                  <MenuList>
                    <Link to="/doctorAppointment">
                      <MenuItem>Doctor Appointment</MenuItem>
                    </Link>
                    <Link to="/petGromming">
                      <MenuItem>Gromming Session</MenuItem>
                    </Link>
                    <Link to="/dayCare">
                      <MenuItem>Day Care</MenuItem>
                    </Link>
                    <Link to="/petMating">
                      <MenuItem>Mating</MenuItem>
                    </Link>
                    <Link to="/petPedia">
                      <MenuItem>Petpedia</MenuItem>
                    </Link>
                    <Link to="/locationsForPets">
                      <MenuItem>Location for Pets</MenuItem>
                    </Link>
                  </MenuList>
                </Menu> */}
              </li>
              <li className={activeItem === "myPets" ? "active-item" : ""} >
                <NavLink to="/myPets" 
               
                onClick={() => handleItemClick("myPets")}
                >
                My Pets
                </NavLink>
              </li>
              <li className={activeItem === "petDiary" ? "active-item" : ""}>
                <NavLink to="/petDiary"
                 onClick={() => handleItemClick("petDiary")}
                 >
                  Pet Diary
                </NavLink>
              </li>

              <li></li>
              <li>
                <Wrap>
                  <WrapItem>
                    <Link to="/userProfile">
                      <Avatar
                        name="Dan Abrahmov"
                        src="https://bit.ly/dan-abramov"
                      />
                    </Link>
                  </WrapItem>
                </Wrap>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default CustomerNavbar;
