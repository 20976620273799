import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import ServiceFeatures from './ServiceFeatures';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <header className={`${styles.header} ${isScrolled ? styles.scrolled : ''}`}>
        <div className={styles.headerContent}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/36cfcad6b36251f6cfd91438fce91cf0e6ab5c0d9f5bedc73498f634519828cd?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
            alt="Logo"
            className={styles.logo}
          />
          <button className={styles.menuButton} onClick={toggleMenu}>
            <span className={`${styles.menuIcon} ${isMenuOpen ? styles.open : ''}`}></span>
          </button>
          <nav className={`${styles.navigation} ${isMenuOpen ? styles.open : ''}`}>
            <a href="#services" className={styles.navLink} onClick={toggleDropdown}>
              Services
            </a>
            <a href="book-a-vet" className={styles.navLink}>Book a Vet</a>
            <a href="#shopping" className={styles.navLink}>Shopping</a>
            <a href="#about" className={styles.navLink}>About us</a>
            <button className={styles.loginButton}>LOG IN</button>
          </nav>
        </div>
      </header>

      {isDropdownOpen && (
        <div className={styles.dropDownMain}>
          <ServiceFeatures />
        </div>
      )}
    </>
  );
};

export default Header;
